export const irrigationEvents = [
  {
    id: 1,
    actionName: 'irrigate',
    actionSettings: {
      start_time_stamp: '', // is it supported?
      originTank: '',
      room: '',
      side1: '',
      volume: 0.0,
      duration: 0,
    },
    originTankOptions: [ // get from db drainage-tanks
      {
        id: 1,
        description: '',
        ros_irrigation_id: 'plc_unit',
        ros_namespace: '/brusaporto/room_1/tank_feedback',
      },
      {
        id: 2,
        description: '',
        ros_irrigation_id: 'plc_unit',
        ros_namespace: '/brusaporto/room_2/tank_feedback',
      },
    ],
  },
  // {
  //   id: 2,
  //   actionName: 'fill_tank',
  //   actionSettings: {
  //     start_time_stamp: '', // is it supported?
  //     duration: 0,
  //     originTank: '',
  //     destinationTank: '', // ??
  //     volume: 0.0,
  //   },
  //   originTankOptions: [ // get from db drainage-tanks and mixing-unit
  //     {
  //       id: 1,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //       ros_namespace: '/brusaporto/mixing_unit/tank_feedback',
  //     },
  //     {
  //       id: 2,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //       ros_namespace: '/brusaporto/room_1/tank_feedback',
  //     },
  //     {
  //       id: 3,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //       ros_namespace: '/brusaporto/room_2/tank_feedback',
  //     },
  //   ],
  //   destinationTankOptions: [ // get from db ????
  //   ],
  // },
  // {
  //   id: 3,
  //   actionName: 'recalibrate_tank',
  //   actionSettings: {
  //     start_time_stamp: '', // is it supported?
  //     duration: 0,
  //     originTank: '',
  //     volume: 0.0,
  //     ph: {
  //       phMin: '',
  //       phRefMin: '',
  //       phRef: '',
  //       phRefMax: '',
  //       phMax: '',
  //     },
  //     ec: {
  //       ecMin: '',
  //       ecRefMin: '',
  //       ecRef: '',
  //       ecRefMax: '',
  //       ecMax: '',
  //     },
  //   },
  //   originTankOptions: [ // get from db mixin-unit
  //     {
  //       id: 1,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //       ros_namespace: '/brusaporto/mixing_unit/tank_feedback',
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   actionName: 'send_to_tank',
  //   actionSettings: {
  //     start_time_stamp: '', // supported?
  //     duration: 0,
  //     originTank: '',
  //     destinationTank: '',
  //     volume: 0.0,
  //   },
  //   destinationTankOptionsMixinUnit: [
  //     {
  //       id: 2,
  //       description: 'Room 1',
  //       ros_irrigation_id: 'room1',
  //     },
  //     {
  //       id: 3,
  //       description: 'Room 2',
  //       ros_irrigation_id: 'room2',
  //     },
  //     {
  //       id: 4,
  //       description: 'Waste',
  //       ros_irrigation_id: 'waste',
  //     },
  //   ],
  //   destinationTankOptionsRooms: [
  //     {
  //       id: 2,
  //       description: 'Mixing  Unit',
  //       ros_irrigation_id: '',
  //     },
  //     {
  //       id: 4,
  //       description: 'Waste',
  //       ros_irrigation_id: 'waste',
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   actionName: 'circulate_tank',
  //   actionSettings: {
  //     start_time_stamp: '',
  //     duration: 0,
  //     originTank: '',
  //     volume: 0.0,
  //   },
  //   originTankOptions: [ // get from db drainage tanks or mixing-unit tanks
  //     {
  //       id: 1,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //       ros_namespace: '/brusaporto/mixing_unit/tank_feedback',
  //     },
  //     {
  //       id: 1,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //     },
  //     {
  //       id: 2,
  //       description: '',
  //       ros_irrigation_id: 'plc_unit',
  //     },
  //   ],
  // },
]
