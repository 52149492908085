<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="irrigationLineData && !loading">
      <h1> {{ irrigationLineName }} </h1>
      <div class="cards">
        <div class="cards-container row d-flex wrap align--start">
          <!-- Irrigation Line Data -->
          <data-card v-for="(dataName, index) in dataNames" :key="'I' +index"
            :id="irrigationLineId"
            :data-name="dataName"
            :data-icon="getDataIcon(dataName)"
            :data-now-level="currentLineData[dataName].level"
            :data-now-val="currentLineData[dataName].val"
            :data-now-units="currentLineData[dataName].units"
            :data-now-ref="currentLineData[dataName].ref"
            :is-expandable="false"
          />
          <div class="flex xs12"> </div>

          <template>
            <at-irrigation-map
              :mapName="$t('irrigation.irrigationMap') + ' - ' + irrigationLineName"
              :irrigation-map-loading="irrigationMapLoading"
              :timeline-start-date="irrigationMapStartDate"
              :timeline-end-date="irrigationMapEndDate"
              :irrigation-map="irrigationMap"
              :editable="false"
              :data-point="dataPoint"
              context="line"
              @onOptionClick="onOptionClick"
              v-on="$listeners"
            >
              <at-irrigation-map-current-action v-if="lineAction"
                :irrigation-line-name="irrigationLineName"
                :action="lineAction"
                @addIrrigationEvent="addIrrigationEvent"
                @changeIrrigationEventStatus="changeIrrigationEventStatus"
                slot="currentAction"
              />
            </at-irrigation-map>
          </template>
          <!-- Tank overview -->
          <div class="flex xs12" v-for="(group, index) in groupedTanks" :key="index">
            <div class="row">
              <irrigation-tank-card v-for="tank in group" :key="tank.id"
                :irrigation-line-id="tank.irrigation_line_id"
                :irrigation-line-name="irrigationLineName"
                :tank-id="tank.id"
                :tank-name="tank.name"
                :tank-type="tank.type"
                :tank-icon="getTankIcon(tank.type)"
                :tank-capacity="tank.capacity"
                :tank-now="currentTankData[tank.id]"
                :settings="settings[tank.id]"
                @setAction="changeCurrentTankActionStatus"
                @saveTankSettings="saveTankSettings"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tank current action Modal start, pause and stop -->
    <va-modal
      v-model="showModal"
      :noOutsideDismiss="true"
      :hideDefaultActions="true"
      ref="modal"
    >

      <template v-slot:header>
        <h6>{{ modalSubTitle }}</h6>
        <h4>{{ modalTitle }}</h4>
      </template>

      <template slot="actions">
        <div class="row">
          <div class="flex xs12 text-right">
            <va-button @click="closeModal()" flat color="gray">{{ $t('modal.cancel') }}</va-button>
            <va-button @click="confirmChangeCurrentTankActionStatus()" color="success" class="mr-0">{{ $t('modal.confirm') }}</va-button>
          </div>
        </div>
      </template>

      <div class="row">
        <div class="flex xs12">
          {{ modalMessage }}
        </div>
      </div>

    </va-modal>
    <!-- /Modal start, pause and stop -->

    <!-- Edit event modal -->
    <va-modal
      v-model="showEditEventModal"
      :noOutsideDismiss="true"
      :hideDefaultActions="true"
      ref="editEventModal"
    >
      <template v-slot:header>
        <h6>{{ editEventModalSubTitle }}</h6>
        <h4>{{ editEventModalTitle }}</h4>
      </template>

      <template slot="actions">
        <div class="row">
          <div class="flex xs4"/>
          <div class="flex xs8 text-right">
            <va-button @click="cancelEditEvent()" flat color="gray">
              {{ $t('modal.cancel') }}
            </va-button>
            <va-button v-if="editOption || moveOption" @click="editEvent()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
            <va-button
              v-if="deleteOption"
              @click="deleteEvent()"
              color="danger"
            >
              {{ $t('modal.delete') }}
            </va-button >
          </div>
        </div>
      </template>

      <div v-if="deleteOption">
        <div class="row">
          <div class="flex xs12">
            {{ modalMessage }}
          </div>
          <div class="flex xs12 mt-3">
            {{ $t(`growing.irrigationEventsEdition.${selectedEvent.action.toLowerCase()}`) + ' - ' + selectedEvent.destination }}
          </div>
        </div>
      </div>
      <div v-else-if="editOption">
        <div>
          <at-irrigation-event-form v-if="selectedEvent.action.toLowerCase() === 'irrigation'"
            :event="selectedEvent"
            :editable-options="irrigationEventEditableOptions"
          />
          <at-irrigation-event-form v-else-if="selectedEvent.action.toLowerCase() === 'fill'"
            :event="selectedEvent"
            :editable-options="fillEventEditableOptions"
          />
          <at-irrigation-event-form v-else-if="selectedEvent.action.toLowerCase() === 'recalibration'"
            :event="selectedEvent"
            :editable-options="recalibrationEventEditableOptions"
          />
          <at-irrigation-event-form v-else-if="selectedEvent.action.toLowerCase() === 'circulation' || selectedEvent.action.toLowerCase() === 'circulate'"
            :event="selectedEvent"
            :editable-options="circulateEventEditableOptions"
          />
        </div>
      </div>
    </va-modal>

    <!-- Add event modal -->
    <va-modal
      v-model="showAddIrrigationEventModal"
      :noOutsideDismiss="true"
      :hideDefaultActions="true"
      ref="addIrrigationEventModal"
      size="large"
    >
      <template v-slot:header>
        <h6>{{ addIrrigationEventModalSubTitle }}</h6>
        <h4>{{ addIrrigationEventModalTitle }}</h4>
      </template>

      <template>
        <at-irrigation-event-form
          ref="addNewIrrigationEventForm"
          :irrigation-line-id="irrigationLineId"
          :add-event="true"
          :irrigation-possible-events="irrigationPossibleEvents"
          :control-groups="controlGroupsData"
          :tanks="groupedTanks"
          :mixingTanks="groupedTanks"
          :all-tanks-grouped="allTanksGrouped"
        />
      </template>

      <template slot="actions">
        <div class="row">
          <div class="flex xs4"/>
          <div class="flex xs8 text-right">
            <va-button @click="cancelAddIrrigationEvent()" flat color="gray">
              {{ $t('modal.cancel') }}
            </va-button>
            <va-button @click="confirmAddIrrigationEvent()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </div>
      </template>

    </va-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import axios from 'axios'
import * as socketio from '../../../utilities/socketio'
import { styleHelpers } from '@/services/atmosphere-ui'
import { getUTCtimeString, getLocalNowTimeDate, getTimeFormat } from '@/services/atmosphere-ui/helpers/TimeHelpers'
import { irrigationEvents } from '@/data/irrigationEventsSettings'
import DataCard from '../../../services/atmosphere-ui/components/cards/at-data-card/AtDataCard'
import IrrigationTankCard from '@/services/atmosphere-ui/components/cards/at-irrigation-tank-card/AtIrrigationTankCard'

export default {
  name: 'irrigation-line-card-view',
  data () {
    return {
      loading: false,
      notFound: false,
      showModal: false,
      mapHash: '',
      modalTitle: '',
      modalSubTitle: '',
      modalMessage: '',
      selectedControlAction: '',
      selectedTankAction: '',
      selectedActionKey: '',
      dataNames: ['ph', 'electrical_conductivity', 'orp', 'temperature', 'dissolved_oxygen', 'volume_flow'],

      irrigationMap: [],
      irrigationMapLoading: false,

      irrigationLineData: null,
      controlGroupsData: [],
      currentLineData: {},

      eventLastState: null,

      lineAction: null,

      lineActionProgress: {},
      lineActionElsapsedTime: '',
      lineActionName: '',
      lineActionOrigin: '',
      lineActionDestination: '',
      lineActionControlAreaIds: [],
      lineActionControlAreas: [],
      lineActionEventKey: '',

      currentTankData: {},

      futureIrrigations: [],
      pastIrrigations: [],

      start: null,
      end: null,
      allTanksGrouped: {},
      tanks: [],
      groupedTanks: {},
      settings: {},
      dataPoint: '',
      selectedInvertal: {},

      selectedData: {
        originTank: '',
        destinationTank: '',
      },

      showEditEventModal: false,
      editEventModalSubTitle: '',
      editEventModalTitle: '',

      showOption: '',
      editOption: false,
      deleteOption: false,
      moveOption: false,

      showAddIrrigationEventModal: false,
      addIrrigationEventModalTitle: '',
      addIrrigationEventModalSubtitle: '',
      irrigationPossibleEvents: [],
      selectedIrrigationEvents: {},
      irrigationEventsSelectionErrors: '',

      selectedEvent: null,
      irrigationEventMoveOptions: ['start_time_stamp'],
      irrigationEventEditableOptions: ['start_time_stamp', 'origin', 'destination', 'duration'],
      fillEventEditableOptions: ['start_time_stamp', 'origin', 'destination', 'duration', 'volume'],
      emptyEventEditableOptions: ['start_time_stamp', 'origin', 'duration'],
      desinfectionEventEditableOptions: ['duration', 'volume'],
      rinseEventEditableOptions: ['start_time_stamp', 'duration', 'volume'],
      circulateEventEditableOptions: ['start_time_stamp', 'origin', 'duration', 'volume'],
      recalibrationEventEditableOptions: ['start_time_stamp', 'origin', 'duration', 'volume'],
      recoverEventEditableOptions: ['start_time_stamp', 'origin', 'destination', 'duration', 'volume'],
    }
  },
  created () {
    this.$level.value = 'bird'
    this.$root.$on('selectedInvertal', this.onSelectedInterval)
    this.$store.commit('setTimelineMinDate', null)
  },
  mounted () {
    this.initializeView()
  },
  computed: {
    currentEvent () {
      const now = moment.utc()
      const timeElapsed = moment.duration('00:' + this.lineActionElsapsedTime.split(' (')[0])
      const start = now.subtract(timeElapsed, 'seconds')

      return {
        ...this.lineAction,
        start_time_stamp: start.format().replace('Z', ''),
        irrigation_line_id: this.irrigationLineId,
        duration: moment.duration(this.lineActionElsapsedTime.split(' (')[0]).asSeconds(),
      }
    },
    activeFarm () {
      return this.$farmId ? this.$farmId : 300
    },
    irrigationLineId () {
      return this.irrigationLineData ? this.irrigationLineData.id : parseInt(this.$route.params.irrigationLineId)
    },
    irrigationLineName () {
      return this.irrigationLineData ? this.irrigationLineData.name : ''
    },
    irrigationMapStartDate () {
      if (this.selectedInvertal.start) {
        return moment.utc(this.selectedInvertal.start).format()
      }

      const factoryNow = getLocalNowTimeDate()
      const factoryYesterday = new Date(factoryNow.getTime() - (24 * 60 * 60 * 1000) * 1)
      factoryYesterday.setHours(0)
      factoryYesterday.setMinutes(0)
      factoryYesterday.setSeconds(0)

      return factoryYesterday.toString()
    },
    irrigationMapEndDate () {
      if (this.selectedInvertal.end) {
        return moment.utc(this.selectedInvertal.end).format()
      }

      const factoryNow = getLocalNowTimeDate()
      const factoryTomorrow = new Date(factoryNow.getTime() + (24 * 60 * 60 * 1000) * 1)
      factoryTomorrow.setHours(23)
      factoryTomorrow.setMinutes(59)
      factoryTomorrow.setSeconds(59)

      return factoryTomorrow.toString()
    },
    addIrrigationEventFormReady () {
      return this.$refs.addNewIrrigationEventForm ? this.$refs.addNewIrrigationEventForm.formReady : false
    },
  },
  methods: {
    initializeView () {
      this.loading = true
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`irrigation_line_${this.irrigationLineId}`])
      socketio.socket.emit('get_one_irrigation_line', this.activeFarm, this.irrigationLineId)
      socketio.socket.on('get_one_irrigation_line_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
          this.notFound = true
        } else {
          if (parsed.data.id === this.irrigationLineId) {
            this.irrigationLineData = parsed.data.data
            this.controlGroupsData = (this.irrigationLineData.control_groups).sort((a, b) => (a.id > b.id ? 1 : -1))
            this.tanks = (this.irrigationLineData.tanks).sort((a, b) => (a.id > b.id ? 1 : -1))
            this.initializeDataCards()
            this.getAllTanks()
          }
          this.loading = false
        }
      })

      this.selectedInvertal = {
        start: new Date(this.irrigationMapStartDate),
        end: new Date(this.irrigationMapEndDate),
        dataPoint: 'day',
      }

      this.onSelectedInterval(this.selectedInvertal)
    },
    initializeDataCards () {
      for (let i = 0; i < this.dataNames.length; i++) {
        this.currentLineData = {
          ...this.currentLineData,
          [this.dataNames[i]]: {},
        }
      }
      this.listenLineCurrentData()

      this.groupedTanks = this.tanks.reduce((groups, item) => ({
        ...groups,
        [item.type]: [...(groups[item.type] || []), item],
      }), {})

      delete this.groupedTanks['dosing-tank']
      // delete this.groupedTanks['fertiliser-tank']

      for (let i = 0; i < this.tanks.length; i++) {
        this.currentTankData = {
          ...this.currentTankData,
          [this.tanks[i].id]: {},
        }

        this.settings = {
          ...this.settings,
          [this.tanks[i].id]: this.tanks[i].settings,
        }
        this.listenTankCurrentData(this.tanks[i].id, this.tanks[i].type)
      }
      this.listenIrrigationMapUpdate()
    },
    getAllTanks () {
      axios.get(`${socketio.structuredApiUrl}/farms/${this.activeFarm}/tanks`, { params: { per_page: 50 } })
        .then(response => {
          this.allTanksGrouped = response.data.data.reduce((groups, item) => ({
            ...groups,
            [item.type]: [...(groups[item.type] || []), item],
          }), {})
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    listenLineCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`irrigation_line_${this.irrigationLineId}`])
      socketio.socket.on('irrigation_line_irrigation_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.irrigation_line_id === this.irrigationLineId) {
            const parsedData = parsed.data.data
            for (let i = 0; i < this.dataNames.length; i++) {
              this.currentLineData[this.dataNames[i]] = parsedData[this.dataNames[i]]
              this.lineAction = parsedData
              this.lineActionProgress = parsedData.progress
              this.lineActionElsapsedTime = parsedData.progress.elapsed_time
              this.lineActionName = parsedData.action
              this.lineActionOrigin = parsedData.origin
              this.lineActionDestination = parsedData.destination
              this.lineActionEventKey = parsed.data.data.key
            }
          }
        }
      })
    },
    listenTankCurrentData (id, type) {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`tank_${id}`])
      socketio.socket.on('tank_water_conditions_current_update', data => {
        let parsed = {}
        try {
          parsed = JSON.parse(data)
        } catch (e) {
          console.log(e)
          console.log(data)
        }
        if (Object.keys(parsed).length !== 0) {
          if (parsed.status >= 400 && parsed.status < 500) {
            console.log(parsed.message)
          } else {
            const parsedData = parsed.data.data
            const tankId = parsed.data.tank_id

            this.currentTankData[tankId] = parsedData
          }
        }
      })
    },
    getIrrigationMap (start, end) {
      this.irrigationMapLoading = true
      socketio.socket.emit('get_irrigation_line_irrigation_map', this.activeFarm, this.irrigationLineId, { from: start, to: end })
      socketio.socket.on('get_irrigation_line_irrigation_map_response', data => {
        let parsed = {}
        try {
          parsed = JSON.parse(data)
          if (Object.keys(parsed).length !== 0) {
            if (parsed.status >= 400 && parsed.status < 500) {
              console.log(parsed.message)
            } else {
              if (parsed.data.irrigation_line_id === this.irrigationLineId) {
                this.irrigationMap = parsed.data.data
                this.irrigationMapLoading = false
              }
            }
          }
        } catch (e) {
          console.log(e)
          console.log(data)
        }
      })
    },
    // getDestinationShortName (event, isFuture) {
    //   if (!isFuture) {
    //     const roomNumber = event.destination.split('Room ')[1].split(' - ')[0]
    //     const roomName = event.destination.split(' - ')[0]
    //     let controlAreasNames = ''

    //     const controlAreas = event.destination.split(', ')
    //     for (let j = 0; j < controlAreas.length; j++) {
    //       const controlAreaNameSplit = controlAreas[j].split(`${roomName} - Bench `)[1].split(' ')

    //       controlAreasNames += `R${roomNumber}-${controlAreaNameSplit}` + (j < (controlAreas.length - 1) ? '</br>' : '')
    //     }

    //     return controlAreasNames
    //   } else {
    //     if (event.action.toLowerCase() === 'irrigation') {
    //       console.log(event)
    //     }
    //     return event.destination.replace('Room ', 'R')
    //   }
    // },
    // concatIrrigationMapPastFuture () { // FOR TEST ONLY
    //   this.testMap = JSON.parse(JSON.stringify(this.pastIrrigations))
    //   for (const event in this.pastIrrigations) {
    //     this.testMap[event].origin = ''
    //     this.testMap[event].origin_short_name = ''
    //     this.testMap[event].destination = this.getControlAreasNames(this.testMap[event].control_areas)
    //     this.testMap[event].destination_short_name = this.getDestinationShortName(this.testMap[event], false)
    //     this.testMap[event].action = 'Irrigation'
    //   }

    //   for (const event in this.futureIrrigations) {
    //     // console.log(this.futureIrrigations[event])
    //     this.futureIrrigations[event].origin_short_name = ''
    //     this.futureIrrigations[event].destination_short_name = this.getDestinationShortName(this.futureIrrigations[event], true)

    //     this.testMap.push(this.futureIrrigations[event])
    //   }
    // },
    getControlAreasNames (controlAreasArray) {
      let names = ''
      for (const i in controlAreasArray) {
        const cgName = controlAreasArray[i].control_group_name ? controlAreasArray[i].control_group_name + ' - ' : ''
        names = names.concat(cgName + controlAreasArray[i].name + ',')
      }
      return names.slice(0, -1)
    },

    addCurrentEventToMap () {
      let currentEventKey = ''
      if (this.lineAction.progress.state.toLowerCase() === 'running' || this.lineAction.progress.state.toLowerCase() === 'paused') {
        currentEventKey = this.lineAction.key

        const currentEventOnMap = this.irrigationMap.find(event => event.key === currentEventKey)

        if (currentEventOnMap === undefined) {
          const indexFirstFutureEvent = this.irrigationMap.findIndex(event => event.type === 'future-event')

          if (indexFirstFutureEvent !== -1) {
            this.irrigationMap.splice(indexFirstFutureEvent, 0, this.currentEvent)
          }
        }
      } else {
        // console.log('no event is running')
        // if (this.eventLastState) {
        //   if (this.eventLastState.toLowerCase() === 'running') {
        //     console.log('event status changed')
        //   }
        // }
      }
      this.eventLastState = this.lineAction.progress.state
    },
    listenIrrigationMapUpdate () {
      socketio.initialiseSocket()
      socketio.socket.on('irrigation_line_irrigation_map_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.irrigation_line_id === this.irrigationLineId) {
            this.getIrrigationMap(getUTCtimeString(new Date(this.irrigationMapStartDate)).slice(0, -1), getUTCtimeString(new Date(this.irrigationMapEndDate)).slice(0, -1))
          }
        }
      })
    },
    onSelectedInterval (interval) {
      this.selectedInvertal = interval
      this.dataPoint = this.selectedInvertal.dataPoint
      this.enableSlider = true

      this.getIrrigationMap(getUTCtimeString(this.selectedInvertal.start).slice(0, -1), getUTCtimeString(this.selectedInvertal.end).slice(0, -1))
    },
    getDataIcon (dataName) {
      return styleHelpers.growingIcon('irrigation', dataName)
    },
    getTankIcon (tankType) {
      let icon = ''
      switch (tankType) {
        case 'recovery-tank':
          icon = 'at-icon l_tank2'
          break
        case 'fresh-water-tank':
        case 'drainage-tank':
          icon = 'at-icon l_tank5'
          break
        default:
          icon = 'at-icon l_water_volume2'
          break
      }

      return icon
    },
    onOptionClick (value) {
      this.selectedEvent = JSON.parse(JSON.stringify(value.irrigationEvent))
      const option = value.option

      switch (option) {
        case 'delete':
          this.deleteOption = true
          this.moveOption = false
          this.editOption = false
          this.editEventModalSubTitle = this.$t('buttons.delete')
          this.modalMessage = this.$t('growing.irrigationEventsEdition.deleteMessage')
          break

        case 'edit':
          if (typeof this.selectedEvent.duration === 'object') {
            this.$set(this.selectedEvent, 'durationString', moment.utc(this.selectedEvent.duration.ref * 1000).format('HH:mm:ss'))
          } else {
            this.$set(this.selectedEvent, 'durationString', moment.utc(this.selectedEvent.duration * 1000).format('HH:mm:ss'))
          }
          this.$set(this.selectedEvent, 'startTime', getTimeFormat(this.selectedEvent.start_time_stamp, 'medium', true))

          this.setOriginOptions()

          this.deleteOption = false
          this.moveOption = false
          this.editOption = true
          this.editEventModalSubTitle = this.$t(`growing.irrigationEventsEdition.${value.irrigationEvent.action.toLowerCase()}`)
          this.modalMessage = this.$t('growing.irrigationEventsEdition.deleteMessage')
          break

        default:
          this.deleteOption = false
          this.moveOption = false
          this.editOption = false
          break
      }

      this.showEditEventModal = true
    },

    setOriginOptions () {
      const originTank = this.tanks.find(tank => this.irrigationLineName + ' ' + tank.name === this.selectedEvent.origin)
      const options = []

      if (originTank) {
        const selectedOrigin = {
          id: 0,
          description: originTank.name,
          ros_irrigation_id: originTank.settings.ros.ros_irrigation_id.value,
          ros_namespace: originTank.settings.ros.ros_namespace.value,
        }

        this.$set(this.selectedEvent, 'originTank', selectedOrigin)
        this.$set(options, 0, selectedOrigin)

        if (this.selectedEvent.action.toLowerCase() === 'irrigation' || this.selectedEvent.action.toLowerCase() === 'irrigate') {
          let countOptions = 1

          if (originTank.type !== 'fresh-water-tank') {
            this.$set(options, countOptions,
              {
                id: countOptions,
                description: this.groupedTanks['fresh-water-tank'][0].name,
                ros_irrigation_id: this.groupedTanks['fresh-water-tank'][0].settings.ros.ros_irrigation_id.value,
                ros_namespace: this.groupedTanks['fresh-water-tank'][0].settings.ros.ros_namespace.value,
              },
            )
            countOptions++
          }

          for (let i = 0; i < this.groupedTanks['drainage-tank'].length; i++) {
            if (this.groupedTanks['drainage-tank'][i].name !== originTank.name) {
              const option = {
                id: countOptions,
                description: this.groupedTanks['drainage-tank'][i].name,
                ros_irrigation_id: this.groupedTanks['drainage-tank'][i].settings.ros.ros_irrigation_id.value,
                ros_namespace: this.groupedTanks['drainage-tank'][i].settings.ros.ros_namespace.value,
              }

              this.$set(options, countOptions, option)
              countOptions++
            }
          }
        } else if (this.selectedEvent.action.toLowerCase() === 'fill') {
          for (let i = 0; i < this.groupedTanks['fresh-water-tank'].length; i++) {
            this.$set(options, 0,
              {
                id: i,
                description: this.groupedTanks['fresh-water-tank'][i].name,
                ros_irrigation_id: this.groupedTanks['fresh-water-tank'][i].settings.ros.ros_irrigation_id.value,
                ros_namespace: this.groupedTanks['fresh-water-tank'][i].settings.ros.ros_namespace.value,
              },
            )
          }
        }

        this.$set(this.selectedEvent, 'originTankOptions', options)
      }
    },
    editEvent () {
      const day = this.selectedEvent.start_time_stamp.split('T')[0]
      const newTime = getUTCtimeString(new Date(day + 'T' + this.selectedEvent.startTime))
      this.selectedEvent.start_time_stamp = newTime.split('.')[0]

      if (typeof this.selectedEvent.duration === 'object') {
        this.selectedEvent.duration.ref = moment.duration(this.selectedEvent.durationString).asSeconds()
        this.selectedEvent.end_time_stamp = moment(this.selectedEvent.start_time_stamp).add(this.selectedEvent.duration.ref, 'seconds').format().split('+')[0]
      } else {
        this.selectedEvent.duration = moment.duration(this.selectedEvent.durationString).asSeconds()
        this.selectedEvent.end_time_stamp = moment(this.selectedEvent.start_time_stamp).add(this.selectedEvent.duration, 'seconds').format().split('+')[0]
      }

      this.selectedEvent.origin = this.irrigationLineName + ' ' + this.selectedEvent.originTank.description

      const index = this.selectedEvent.mapIndex
      delete this.selectedEvent.mapIndex
      delete this.selectedEvent.durationString
      delete this.selectedEvent.startTime

      delete this.selectedEvent.status
      delete this.selectedEvent.status_details

      if ('origin_tank' in this.selectedEvent) {
        const origin = this.selectedEvent.originTank.ros_namespace + '/' + this.selectedEvent.originTank.ros_irrigation_id
        this.selectedEvent.origin_tank = origin
      }

      delete this.selectedEvent.originTank
      delete this.selectedEvent.originTankOptions

      this.irrigationMap.splice(index, 1, this.selectedEvent)

      socketio.socket.emit('modify_irrigation_map', this.irrigationLineId, { data: [this.selectedEvent] })

      this.clearFields()
      this.$refs.editEventModal.ok()
    },
    cancelEditEvent () {
      this.clearFields()
      this.$refs.editEventModal.cancel()
    },
    deleteEvent () {
      this.selectedEvent.delete = true

      this.irrigationMap.splice(this.selectedEvent.mapIndex, 1)
      socketio.socket.emit('modify_irrigation_map', this.irrigationLineId, { data: [this.selectedEvent] })

      this.$refs.editEventModal.cancel()
      this.deleteOption = false
      this.clearFields()
    },
    addIrrigationEvent () {
      this.showAddIrrigationEventModal = true
      this.addIrrigationEventModalSubTitle = ''
      this.addIrrigationEventModaTitle = this.$t('tanks.actions.add')

      this.irrigationPossibleEvents = JSON.parse(JSON.stringify(irrigationEvents))
      for (let i = 0; i < this.irrigationPossibleEvents.length; i++) {
        this.irrigationPossibleEvents[i].selectionActionName = this.$t(`tanks.actions.${this.irrigationPossibleEvents[i].actionName}`)
      }
    },
    confirmAddIrrigationEvent () {
      this.$refs.addNewIrrigationEventForm.confirmAddAction()
      if (!this.addIrrigationEventFormReady) {
        console.log('review form')
      } else {
        const eventType = this.$refs.addNewIrrigationEventForm.selectedIrrigationEvent.actionName
        const data = this.$refs.addNewIrrigationEventForm.setAddNewActionData()
        const emitEventName = eventType === 'irrigate' ? 'add_irrigation' : `add_${eventType}_event`

        socketio.socket.emit(emitEventName, this.irrigationLineId, { data: data })

        this.launchToast(this.$t('tanks.actions.add') + ': ' + this.$t(`tanks.actions.${eventType}`))
        console.log(emitEventName, data)
        this.$refs.addIrrigationEventModal.ok()
      }
    },
    cancelAddIrrigationEvent () {
      this.clearFields()
      this.$refs.addIrrigationEventModal.cancel()
    },

    changeIrrigationEventStatus (value) {
      this.showModal = true
      this.modalTitle = this.$t(`growing.irrigationEventsEdition.${value.actionName}`)
      this.modalMessage = this.$t(`growing.irrigationEventsEdition.${value.actionName.toLowerCase()}_${value.action.toLowerCase()}_message`)

      this.selectedControlAction = value.action // add, irrigate, recalibrate, etc
      this.selectedTankAction = value.actionName
      this.selectedActionKey = value.actionKey ? value.actionKey : ''
    },
    changeCurrentTankActionStatus (value) {
      this.showModal = true
      this.modalTitle = this.$t(`tanks.actions.${value.actionName}`)
      this.modalMessage = this.$t(`tanks.actions.${value.actionName}_${value.action}_message`)

      this.selectedControlAction = value.action // add, irrigate, recalibrate, etc
      this.selectedTankAction = value.actionName
      this.selectedActionKey = value.actionKey ? value.actionKey : ''
    },
    confirmChangeCurrentTankActionStatus () {
      const emitEventName = `${this.selectedControlAction}_irrigation`
      socketio.socket.emit(emitEventName, this.irrigationLineId, this.selectedActionKey)
      this.launchToast(this.$t(`irrigation.${this.selectedControlAction}ToastMessage`))
      this.$refs.modal.close()
    },
    closeModal () {
      this.$refs.modal.close()
    },
    clearFields () {
      this.moveOption = false
      this.editOption = false
      this.deleteOption = false
      this.selectedEvent = {}
    },

    launchToast (text) {
      this.showToast(
        text,
        {
          theme: 'none',
          position: 'top-center',
          duration: 4500,
          fullWidth: true,
        },
      )
    },
    saveTankSettings (tankId) {
      console.log('save_tank_settings', 1, this.irrigationLineId, tankId)

      this.validateTankSettings(this.settings[tankId])
      console.log(this.settings[tankId])
      // socketio.socket.emit('save_tank_settings', 1, this.irrigationLineId, tankId, this.settings[tankId])
    },
    validateTankSettings (settings) {
      for (const settingFamily in settings) {
        for (const setting in settings[settingFamily]) {
          if (settings[settingFamily][setting].type === 'time-seconds') {
            settings[settingFamily][setting].value = moment.duration(settings[settingFamily][setting].timeSecondsString).asSeconds()
            delete settings[settingFamily][setting].timeSecondsString
          } else if (settings[settingFamily][setting].type === 'bool') {
            settings[settingFamily][setting].value = settings[settingFamily][setting].valueSelected.value
            delete settings[settingFamily][setting].valueSelected
          }
        }
      }
    },
  },
  watch: {
    $route () {
      this.initializeView()
    },
    notFound () {
      if (this.notFound) {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
    'selectedIrrigationEvents.actionName' () {
      for (const field in this.selectedData) {
        this.selectedData[field] = {}
      }
    },
    'lineAction' () {
      this.addCurrentEventToMap()
    },
  },
  components: {
    DataCard,
    IrrigationTankCard,
  },
  beforeDestroy () {
    this.$root.$off('selectedInvertal', this.onSelectedInterval)
    socketio.socket.emit('unsubscribe_from_context', [`irrigation_line_${this.irrigationLineId}`])

    for (let i = 0; i < this.tanks.length; i++) {
      socketio.socket.emit('unsubscribe_from_context', [`tank_${this.tanks[i].id}`])
    }
  },
}
</script>

<style lang="scss">
.cards-container {
  .va-card {
    margin: 0;
  }
}
</style>
