<template>
  <div :class="tankNow.level ? 'flex xs6' : 'flex xs4'">
    <va-card
      :style="computedStyle"
      :class="computedClass"
      :title="tankName"
      :card-badges="cardBadges"
    >

      <template slot="actions">
        <!-- <va-button flat small icon="at-icon l_settings1" @click="settingsModal = !settingsModal"/> -->
        <router-link :to="{ name: routerTankName, params: { irrigationLineId: getIrrigationLineId() , tankId: tankId, displayName: tankName } }">
          <va-button flat small icon="at-icon card_plus"/>
        </router-link>
      </template>
      <div class="row">
        <div class="flex xs12">
          <div class="row">
            <!-- Level -->
            <div class="flex xs6" v-if="tankNow.level">
              <template>
                <div class="content">
                  <div class="content__main">
                    <div class="content__main__top">
                      <va-icon :name="tankIcon"></va-icon>
                      <span v-html="unitFormat(tankNow.level.val * tankCapacity / 100, null, 'm3')"></span>
                    </div>
                    <div class="content__main__bottom">
                      {{ $t(`data.level`) }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <!-- Level percentage -->
            <div class="flex xs6" v-if="tankNow.level">
              <template>
                <div class="content__main">
                  <div class="content__main__top">
                    <div class="d-flex justify--center">
                      <div>
                        <va-progress-circle :value="tankNow.level.val">
                          {{ Math.round(tankNow.level.val) }} <sub>%</sub>
                        </va-progress-circle>
                      </div>
                    </div>
                  </div>
                  <div class="content__main__bottom">
                    {{ $t(`data.levelPercent`) }}
                  </div>
                </div>
              </template>
            </div>
            <div v-else class="flex xs12">
              <template>
                <div class="content">
                  <div class="content__main">
                    <div class="content__main__top">
                      <va-icon :name="tankIcon"></va-icon>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- Current Action Progress -->
          <template v-if="activeActions === 0">
            <div class="flex xs12 sm12" :style="{'padding-top': '3.25rem', 'padding-bottom': '4.1rem'}">
              <va-progress-bar :value="0"></va-progress-bar>
            </div>
          </template>

          <template v-else v-for="(event, name) in tankNow.events">
            <div class="row" v-if="event && event.event_key" :key="name">
              <div class="flex xs12 sm12">
                <span>{{ $t(`tanks.actions.${name}`) }}</span>
                <va-progress-bar v-model="event.progress.percent">
                  <span :style="{visibility: event.progress.state !== 'Not running' ? 'visible' : 'hidden'}">
                    {{ Math.round(event.progress.percent) }}%
                  </span>
                </va-progress-bar>
                <div class="row">
                  <div class="flex xs5 sm5">
                    {{ $t('tanks.actions.status') }}: <span><b>{{ $t(`tanks.actions.${event.progress.state}`) }}</b></span>
                  </div>
                  <!-- Controls -->
                  <div class="flex xs2 sm2">
                    <div class="buttons">
                      <va-button v-if="event.progress.state === 'Paused'" flat icon="at-icon l_player_play" @click="setAction('play', name, event.event_key)"/>
                      <va-button v-if="event.progress.state === 'Running'" flat icon="at-icon l_player_pause" @click="setAction('pause', name, event.event_key)"/>
                      <va-button v-if="event.progress.state === 'Running' || event.progress.state === 'Paused'" flat icon="at-icon l_player_stop" @click="setAction('stop', name, event.event_key)"/>
                    </div>
                  </div>
                  <div class="flex xs5 sm5 time__elapsed" :style="{visibility: event.event_key ? 'visible' : 'hidden'}">
                    <template v-if="event.progress.units === 'm3'">
                      <!-- Volume -->
                      <div>
                        <span>{{ $t('data.volume') }}:</span>
                        <span><b>
                          {{ (event.progress.percent / event.progress.total) ? (Math.round(event.progress.percent * event.progress.total) / 100) : 0 }}/{{ Math.round(event.progress.total * 100) / 100 }}
                          {{ getUnitFormated(event.progress.units) }}
                        </b></span>
                      </div>
                      <!-- Time -->
                      <div class="mt-2">
                        <span>{{ $t('timeData.elapsedTime') }}: </span>
                        <span><b>{{ getElapsedTime(event.progress.elapsed_time) }}</b></span>
                      </div>
                    </template>
                    <template v-else-if="event.progress.units === 's' || event.progress.units === 'sec'">
                      <span>{{ $t('timeData.elapsedTime') }}: </span>
                      <span><b>
                        {{ getTimeFormated(event.progress.total * event.progress.percent / 100) }}/{{ getTimeFormated(event.progress.total) }}{{event.progress.total > 3600 ? 'H' : ' min' }}
                      </b></span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </va-card>

    <va-modal
      v-model="settingsModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="settingsModal"
    >

      <template slot="actions">
        <div class="row">
          <div class="flex xs4">
          </div>
          <div class="flex xs8 text-right">
            <va-button @click="cancel()" flat color="gray">
              {{  $t('modal.cancel') }}
            </va-button>
            <va-button @click="confirm()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </div>
      </template>

      <at-settings-form
        :title="`${$t('growing.settings')}`"
        :subtitle="irrigationLineName + ' - ' + tankName"
        :settings-array="settings"
      />

    </va-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { styleHelpers } from '@/services/atmosphere-ui'
import AtSettingsForm from './../../at-settings-form/AtSettingsForm'

export default {
  name: 'irrigation-tank-card',
  data () {
    return {
      dataNames: ['ph'],
      settingsModal: false,
    }
  },
  props: {
    irrigationLineId: {
      type: Number,
    },
    irrigationLineName: {
      type: String,
      default: 'Irrigation Line',
    },
    tankId: {
      type: Number,
      default: 0,
    },
    tankName: {
      type: String,
      default: 'Tank',
    },
    tankType: {
      type: String,
    },
    tankCapacity: {
      type: Number,
    },
    tankIcon: {
      type: String,
      default: 'at-icon l_water_volume2',
    },
    tankNow: {
      type: Object,
      default: () => {
        return {
          events: {},
          temperature: {},
          ph: {},
          electrical_conductivity: {},
          dissolved_oxygen: {},
          orp: {},
          level: {},
        }
      },
    },
    settings: {
      type: Object,
      default () {
        return {}
      },
    },
    cardBadges: {
      type: Array,
    },
  },
  mounted () {
  },
  methods: {
    getIrrigationLineId () {
      return this.irrigationLineData ? this.irrigationLineData.id : parseInt(this.$route.params.irrigationLineId)
    },
    unitFormat (value, valueVar, units) {
      if (valueVar) {
        units = '±' + styleHelpers.unitFormat(Math.round(Math.sqrt(valueVar)), units)
      }
      return styleHelpers.unitFormat(value, units)
    },
    getDataIcon (dataName) {
      return styleHelpers.growingIcon(this.context, dataName)
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    getTimeFormated (seconds) {
      return seconds > 3600 ? moment.utc(seconds * 1000).format('HH:mm:ss') : moment.utc(seconds * 1000).format('mm:ss')
    },
    getElapsedTime (elapsedTime) {
      const time = elapsedTime.split(' (')[0]
      const timeLenght = time.split(':').length

      if (timeLenght <= 2) {
        return time + ' min'
      } else if (timeLenght > 2) {
        return time + 'H'
      } else {
        return time
      }
    },
    setAction (selectedAction, actionName, actionKey) { // play, pause and stop
      this.$emit('setAction', {
        action: selectedAction,
        actionName: actionName,
        actionKey: actionKey,
      })
    },
    cancel () {
      this.$refs.settingsModal.cancel()
    },
    confirm () {
      this.$emit('saveTankSettings', this.tankId)
      this.$refs.settingsModal.ok()
    },
  },
  computed: {
    computedClass () {
      return {
        'va-card--danger': this.$status.value === 'danger',
      }
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
    activeActions () {
      let count = 0
      for (const action in this.tankNow.events) {
        if (this.tankNow.events[action].event_key) {
          count++
        }
      }
      return count
    },
    routerTankName () {
      return this.tankType + '-details'
    },
  },
  components: {
    AtSettingsForm,
  },
}
</script>

<style lang="scss">
.time__elapsed {
  text-align: end;
  padding: 0.5rem 1.25rem;
}

.buttons {
  display: flex;
  justify-content: center;

  .va-button {
    padding: 0.2rem;
    margin: 0.2rem;
    font-size: 1.5rem;
  }
}
</style>
